import { Application } from '@hotwired/stimulus';

import AutochargePreferencesController from './enrollment_engine/autocharge_preferences_controller';
import CheckoutController from './enrollment_engine/checkout_controller';
import LegacyEnrollmentEngineController from './enrollment_engine/legacy_enrollment_engine_controller';
import EnrollmentFormController from './enrollment_engine/enrollment_form_controller';
import ReferralController from './enrollment_engine/referral_controller';
import ModalController from './shared/modal_controller';
import ValidationController from './shared/validation_controller';
import SocialLinksController from './congratulations_page/social_links_controller';
import SocialShareController from './announce_my_enrollment/social_share_controller';
import TurboStreamsController from './shared/turbo_streams_controller';
import AutocompleteController from './enrollment_engine/autocomplete_controller';
import InputValidationsController from './enrollment_engine/input_validations_controller';
import PasswordValidationsController from './shared/password_validations_controller';
import ConditionalFieldController from './enrollment_engine/conditional_field_controller';
import LoadingSpinnerController from './enrollment_engine/loading_spinner_controller';
import FileOpenerController from './enrollment_engine/file_opener_controller';
import CountryStateSelectController from './shared/country_state_select_controller';
import ButtonlessFormController from './shared/buttonless_form_controller';
import DateOfBirthController from './enrollment_engine/date_of_birth_controller';
import ElectivesModalController from './enrollment_engine/electives_modal_controller';
import RecaptchaController from './shared/recaptcha_controller';

const application = Application.start();
application.register('enrollment-engine--autocharge-preferences', AutochargePreferencesController);
application.register('enrollment-engine--checkout', CheckoutController);
application.register('enrollment-engine--buttonless-form', ButtonlessFormController);
application.register('enrollment-engine', LegacyEnrollmentEngineController);
application.register('enrollment-engine--enrollment-form', EnrollmentFormController);
application.register('enrollment-engine--referral', ReferralController);
application.register('modal', ModalController);
application.register('validation', ValidationController);
application.register('turbo-streams', TurboStreamsController);
application.register('congratulations-page--social-links', SocialLinksController);
application.register('announce-my-enrollment--social-share', SocialShareController);
application.register('autocomplete', AutocompleteController);
application.register('enrollment-engine--input-validations', InputValidationsController);
application.register('shared--password-validations', PasswordValidationsController);
application.register('enrollment-engine--conditional-field', ConditionalFieldController);
application.register('enrollment-engine--loading-spinner', LoadingSpinnerController);
application.register('enrollment-engine--file-opener', FileOpenerController);
application.register('country-state-select', CountryStateSelectController);
application.register('date-of-birth', DateOfBirthController);
application.register('electives-modal', ElectivesModalController);
application.register('recaptcha', RecaptchaController);
