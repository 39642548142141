const hideButtonIfDone = (showMoreRowsButton) => {
  const nextRow = document.querySelector('.js-row.hidden');
  if (!nextRow) {
    showMoreRowsButton.classList.add('hidden');
  }
};

const showMoreRowsHandler = (e) => {
  e.preventDefault();
  const nextRow = document.querySelector('.js-row.hidden');
  nextRow.classList.remove('hidden');
  hideButtonIfDone(e.target);
};

const showMoreRowsElement = (showMoreRowsButton) => {
  hideButtonIfDone(showMoreRowsButton);
  showMoreRowsButton.addEventListener('click', showMoreRowsHandler);
};

const showMoreRowsDisconnect = (showMoreRowsButton) => {
  showMoreRowsButton.removeEventListener('click', showMoreRowsHandler);
};

export default () => {
  const showMoreRowsButton = document.querySelector('.js-show-more-rows');
  if (showMoreRowsButton) showMoreRowsElement(showMoreRowsButton);

  return () => {
    if (showMoreRowsButton) showMoreRowsDisconnect(showMoreRowsButton);
  };
};
