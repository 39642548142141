
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['continueButton'];

  selectRadioButton () {
    this.continueButtonTarget.disabled = false;
  }
}
