import { fullDiscountApplied } from './checkoutHelpers';

const fullDiscountHandler = (event) => {
  event.preventDefault();
  event.target.setAttribute('disabled', true);
  const paymentForm = document.getElementById('payment-form');
  paymentForm.submit();
};

const fullDiscountElement = (paymentSubmitBtn) => {
  paymentSubmitBtn.addEventListener('click', fullDiscountHandler);
};

const fullDiscountDisconnect = (paymentSubmitBtn) => {
  paymentSubmitBtn.removeEventListener('click', fullDiscountHandler);
};

export default () => {
  const paymentForm = document.getElementById('payment-form');
  if (paymentForm && fullDiscountApplied()) {
    const paymentSubmitBtns = document.getElementsByClassName('js-credit-card-submit-btn');

    if (paymentSubmitBtns.length > 0) {
      paymentSubmitBtns.forEach((btn) => { fullDiscountElement(btn); });
      return () => {
        paymentSubmitBtns.forEach((btn) => { fullDiscountDisconnect(btn); });
      };
    }
  }

  return () => {};
};
