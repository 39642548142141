import { Controller } from '@hotwired/stimulus';

const setXhttp = (method, url) => {
  const token = document.querySelector('meta[name="csrf-token"]').content;
  const xhttp = new XMLHttpRequest();

  xhttp.open(method, url, true);
  xhttp.setRequestHeader('X-CSRF-Token', token);
  xhttp.setRequestHeader('Content-Type', 'application/json');

  return xhttp;
};

export default class extends Controller {
  OpenDecisionLetter (event) {
    window.open(event.params.payload.letter_url, '_blank');
    this.UpdateEnrollment(event.params.payload.update_enrollment_url);
  }

  UpdateEnrollment (url) {
    const xhttp = setXhttp('POST', url);

    xhttp.onreadystatechange = () => {
      if (this.readyState === 4) {
        if (this.status !== 200) {
          alert('There was an error, please try again.');
        }
      }
    };
    xhttp.send();
  }
}
