import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['buttonsContainer', 'loader'];

  close () {
    this.element.classList.remove('modal--open');
    this.element.classList.add('modal--close');
  }

  showLoader () {
    this.buttonsContainerTarget.classList.add('hidden');
    this.loaderTarget.classList.remove('hidden');
  }
}
