import { Controller } from '@hotwired/stimulus';
import verifyCoupon from '../../modules/enrollments/verifyCoupon';
import dropdownElement from '../../modules/menu';
import changeBatchDate from '../../modules/enrollments/changeBatchDate';
import fullDiscount from '../../modules/checkout/fullDiscount';
import showMoreRows from '../../modules/showMoreRows';

export default class extends Controller {
  connect () {
    this.disconnectListeners = [
      dropdownElement(),
      changeBatchDate(),
      fullDiscount(),
      verifyCoupon(),
      showMoreRows(),
    ];
  }

  disconnect () {
    this.disconnectListeners.forEach(disconnectListener =>
      disconnectListener(),
    );
  }
}
