// controllers/enrollment_engine/checkout_controller.js
import { Controller } from '@hotwired/stimulus';
import { stripeGatewayLoadForm, payStripeIntent, stripeBnplPaymentMethods } from '../../modules/checkout/stripe';
import { stripePaymentIntentCheckoutEnabled, showBnplInstallmentTextAndHideNormalInstallments, hideBnplInstallmentTextAndShowNormalPaymentsInstallments } from '../../modules/checkout/checkoutHelpers';
import PaymentDescription from '../../modules/checkout/paymentDescription';

export default class extends Controller {
  static targets = ['payNowButton', 'couponHandle', 'paymentOption', 'couponCodeField', 'checkoutForm', 'paymentMethod']

  connect () {
    this.loadForm();
  }

  // eslint-disable-next-line class-methods-use-this
  payNow () {
    if (stripePaymentIntentCheckoutEnabled()) {
      payStripeIntent();
    }
  }

  updateBreakdown () {
    const paymentMethod = this.paymentMethodTargets.find(element => element.checked)?.value;
    const paymentInstallmentId = document.querySelector('form[id]').dataset.id;
    const numberOfInstallments = this.updateNumberOfInstallments();
    const paymentDescription = new PaymentDescription(numberOfInstallments,
      paymentMethod,
      paymentInstallmentId);
    paymentDescription.reload();
  }

  updateNumberOfInstallments () {
    if (this.paymentOptionTargets.length === 0) { return undefined; }

    let numberOfInstallments;
    const paymentMethod = this.paymentMethodTargets.find(element => element.checked)?.value;

    if (stripeBnplPaymentMethods().includes(paymentMethod)) {
      showBnplInstallmentTextAndHideNormalInstallments();
      this.paymentOptionTargets[0].checked = true;
      numberOfInstallments = 1;
    } else {
      hideBnplInstallmentTextAndShowNormalPaymentsInstallments();
      numberOfInstallments = this.paymentOptionTargets.find(element => element.checked)?.value;
    }
    return numberOfInstallments;
  }

  // eslint-disable-next-line class-methods-use-this
  loadForm () {
    if (stripePaymentIntentCheckoutEnabled()) {
      this.checkoutFormTarget.classList.add('disabled');
      this.checkoutFormTarget.addEventListener('payment_form_change', (event) => {
        this.payNowButtonTarget.disabled = event.detail.disable_payment;
      });

      stripeGatewayLoadForm()
        .then(() => {
          const alertlabel = document.querySelector('.alert-danger');

          if (!alertlabel) { this.checkoutFormTarget.classList.remove('disabled'); }
        })
        .catch(() => {
          // TODO: SELL-1044
          // This logic is only for subsequent payments
          this.checkoutFormTarget.classList.remove('disabled');
          this.payNowButtonTarget.disabled = true;
        });
    }

    if (this.paymentMethodTargets.length > 0 && this.paymentOptionTargets.length > 0) {
      this.updateNumberOfInstallments();
    }
  }

  disableAllElementsHandle () {
    if (this.couponCodeFieldTarget.value) { this.checkoutFormTarget.classList.add('disabled'); }
  }
}
