import { Controller } from '@hotwired/stimulus';
import flatpickr from 'flatpickr';

export default class extends Controller {
  connect () {
    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 150);
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 18);
    const enrollmentDateOfBirthFormated = this.element.value.replace(/(\d{4})-(\d{2})-(\d{2})/, '$2/$3/$1');
    flatpickr(this.element, {
      dateFormat: 'm/d/Y',
      minDate,
      maxDate,
      defaultDate: enrollmentDateOfBirthFormated,
    });
  }
}
