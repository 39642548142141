const changeEnrollmentDate = (newBatch, enrollmentId) => {
  const form = document.querySelector(`#date_change_form-${enrollmentId}`);
  const enrollmentField = form.querySelector(`#date_change_enrollment_id-${enrollmentId}`);
  const batchField = form.querySelector(`#date_change_batch_sfid-${enrollmentId}`);

  enrollmentField.value = enrollmentId;
  batchField.value = newBatch;
  form.submit();
};

const changeInterestedCourseDate = (target, previousBatch, startDate) => {
  const batchLink = document.querySelector(`a[data-batch-sfid='${previousBatch}']`);
  const date = startDate;

  batchLink.href = batchLink.href.replace(/batches\/\w*\/enrollments/i, `batches/${target.value}/enrollments`);
  date.innerText = target.options[target.selectedIndex].text;
};

const dateChangeHandler = (event) => {
  const dropdown = event.target;
  const link = dropdown.parentNode.querySelector('.js-date-change');
  const startDate = dropdown.parentNode.querySelector('.js-start-date');
  dropdown.classList.add('hidden');
  link.classList.remove('hidden');
  startDate.classList.remove('hidden');

  if (dropdown.dataset.enrollmentId) {
    changeEnrollmentDate(event.target.value, dropdown.dataset.enrollmentId);
  } else if (dropdown.dataset.batchSfid) {
    changeInterestedCourseDate(
      event.target,
      dropdown.dataset.batchSfid,
      startDate,
    );
  }
};

const clickLinkHandler = (event) => {
  event.preventDefault();
  const link = event.target;
  const dateDropdown = link.parentNode.querySelector('select');
  const startDate = link.parentNode.querySelector('.js-start-date');
  link.classList.add('hidden');
  startDate.classList.add('hidden');
  dateDropdown.classList.remove('hidden');
};

const dateChangeElement = (dateChangeElements) => {
  dateChangeElements.forEach((link) => {
    const dateDropdown = link.parentNode.querySelector('select');
    link.addEventListener('click', clickLinkHandler);
    dateDropdown.addEventListener('change', dateChangeHandler);
  });
};

const dateChangeDisconnect = (dateChangeElements) => {
  dateChangeElements.forEach((link) => {
    const dateDropdown = link.parentNode.querySelector('select');
    link.removeEventListener('click', clickLinkHandler);

    dateDropdown.removeEventListener('change', dateChangeHandler);
  });
};

export default () => {
  const dateChangeElements = document.querySelectorAll('.js-date-change');
  dateChangeElement(dateChangeElements);

  return () => {
    dateChangeDisconnect(dateChangeElements);
  };
};
