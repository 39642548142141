import { Controller } from '@hotwired/stimulus';

/* eslint class-methods-use-this: ["error", { "exceptMethods": ["submit"] }] */
export default class ButtonlessFormController extends Controller {
  connect () {
    const inputs = this.element.querySelectorAll('input:not([type=submit]):not([type=hidden])');
    const enterKeyCode = 13;

    inputs.forEach((input) => {
      input.addEventListener('keypress', (event) => {
        if (event.keyCode === enterKeyCode) {
          event.preventDefault();
          input.blur();
        }
      });
    });
  }

  submit (event) {
    event.preventDefault();
    event.target.form.requestSubmit();
  }
}
