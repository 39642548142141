const showLoadingSpinner = () => {
  const spinnerModal = document.getElementById('payment-loading-modal');
  if (spinnerModal) {
    spinnerModal.classList.remove('modal--close');
    spinnerModal.classList.add('modal--open');
  }
};

const hideLoadingSpinner = () => {
  const spinnerModal = document.getElementById('payment-loading-modal');
  if (spinnerModal) {
    spinnerModal.classList.remove('modal--open');
    spinnerModal.classList.add('modal--close');
  }
};

export {
  showLoadingSpinner,
  hideLoadingSpinner,
};
