/* global grecaptcha */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['recaptchaContainer', 'submitButton'];

  connect () {
    this.sitekey = this.element.dataset.recaptchaSitekey;
    this.loadRecaptcha();
  }

  loadRecaptcha () {
    if (!document.querySelector('script[src="https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit"]')) {
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit';
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
      window.onloadCallback = this.initRecaptcha.bind(this);
    } else {
      this.initRecaptcha();
    }
  }

  initRecaptcha () {
    if (typeof grecaptcha !== 'undefined' && typeof grecaptcha.render === 'function') {
      const widgetId = grecaptcha.render(this.recaptchaContainerTarget, {
        sitekey: this.sitekey,
        callback: this.onCaptchaVerified.bind(this),
        'expired-callback': this.onCaptchaExpired.bind(this),
      });
      this.recaptchaWidgetId = widgetId;
    } else {
      console.error('grecaptcha is not defined or render method is not available.');
    }
  }

  onCaptchaVerified () {
    const tokenValue = grecaptcha.getResponse(this.recaptchaWidgetId);

    let recaptchaTokenField = document.querySelector('input[name="recaptcha_token"]');
    if (!recaptchaTokenField) {
      recaptchaTokenField = document.createElement('input');
      recaptchaTokenField.setAttribute('type', 'hidden');
      recaptchaTokenField.setAttribute('name', 'recaptcha_token');
      this.element.appendChild(recaptchaTokenField);
    }
    recaptchaTokenField.value = tokenValue;

    this.updateSubmitButtonState(false);
  }

  onCaptchaExpired () {
    this.updateSubmitButtonState(true);
  }

  updateSubmitButtonState (disabled) {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.disabled = disabled;
    } else {
      console.error('Submit button target not found.');
    }
  }
}
