const copyButtonHandler = (e) => {
  const button = e.target;
  const { url, success } = button.dataset;
  const originalText = button.innerHTML;
  // create a dummy input to copy from
  const dummyInput = document.createElement('textarea');
  dummyInput.value = url;
  document.body.appendChild(dummyInput);
  dummyInput.select();
  document.execCommand('copy');
  document.body.removeChild(dummyInput);

  // update the button text for two seconds
  button.innerHTML = success;
  button.disabled = true;
  setTimeout(() => {
    button.innerHTML = originalText;
    button.disabled = false;
  }, 2000);
};

const copyTextElement = (copyButton) => {
  copyButton.addEventListener('click', copyButtonHandler);
};

const copyTextDisconnect = (copyButton) => {
  copyButton.removeEventListener('click', copyButtonHandler);
};

export default () => {
  const copyButton = document.querySelector('.js-copy-text');
  if (copyButton) copyTextElement(copyButton);

  return () => {
    if (copyButton) copyTextDisconnect(copyButton);
  };
};
