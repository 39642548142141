const dropdownToggleHandler = (event) => {
  const menu = event.target.closest('.main-header__menu');
  const dropdown = menu.querySelector('.js-main-header__menu__dropdown');
  dropdown.classList.toggle('hidden');
};

const dropdownHideHandler = (event) => {
  const e = event || window.event;
  const target = e.target || e.srcElement;
  let dropdown;
  document.querySelectorAll('.js-main-header__menu__btn').forEach((dropdownBtn) => {
    dropdown = dropdownBtn.parentNode.querySelector('.js-main-header__menu__dropdown');
    if (!dropdown.contains(target) && !dropdownBtn.contains(target)) {
      dropdown.classList.add('hidden');
    }
  });
};

const dropdownElement = (dropdownBtns) => {
  document.addEventListener('click', dropdownHideHandler);
  let dropdown;
  dropdownBtns.forEach((dropdownBtn) => {
    dropdown = dropdownBtn.parentNode.querySelector(
      '.js-main-header__menu__dropdown');
    if (dropdown && dropdownBtn) {
      dropdownBtn.addEventListener('click', dropdownToggleHandler);
    }
  });
};

const dropdownsDisconnect = (dropdownBtns) => {
  document.removeEventListener('click', dropdownHideHandler);
  dropdownBtns.forEach((dropdownBtn) => {
    const dropdown = dropdownBtn.parentNode.querySelector(
      '.js-main-header__menu__dropdown');
    if (dropdown && dropdownBtn) {
      dropdownBtn.removeEventListener('click', dropdownToggleHandler);
    }
  });
};

export default () => {
  const dropdownBtns = document.querySelectorAll('.js-main-header__menu__btn');
  dropdownElement(dropdownBtns);

  return () => {
    dropdownsDisconnect(dropdownBtns);
  };
};
