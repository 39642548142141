import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'socialShareText',
    'linkedinButton',
    'linkedinButtonMobile',
    'facebookButton',
    'facebookButtonMobile',
    'twitterButton',
    'twitterButtonMobile',
    'previewText',
  ]

  updateShareText () {
    const socialShareText = this.socialShareTextTarget.value;
    const socialShareTextExtraSpace = socialShareText + String.fromCharCode(10);

    this.previewTextTarget.value = socialShareText;
    this.socialShareTextTarget.previousElementSibling.innerText = socialShareTextExtraSpace;
    this.previewTextTarget.previousElementSibling.innerText = socialShareTextExtraSpace;
    this.updateTwitterShareUrl();
  }

  updateTwitterShareUrl () {
    const twitterShareUrl = new URL(this.twitterButtonTarget.getAttribute('href'));
    const socialShareText = this.socialShareTextTarget.value;

    twitterShareUrl.searchParams.set('text', socialShareText);
    this.twitterButtonTarget.setAttribute('href', twitterShareUrl);
    this.twitterButtonMobileTarget.setAttribute('href', twitterShareUrl);
  }

  async copyLinkedinShareText (event) {
    const button = this.linkedinButtonTarget;

    this.copyShareText(event, button);
  }

  async copyLinkedinMobileShareText (event) {
    const button = this.linkedinButtonMobileTarget;

    this.copyShareText(event, button);
  }

  async copyFacebookShareText (event) {
    const button = this.facebookButtonTarget;

    this.copyShareText(event, button);
  }

  async copyFacebookMobileShareText (event) {
    const button = this.facebookButtonMobileTarget;

    this.copyShareText(event, button);
  }

  copyShareText (event, button) {
    const originalCaption = button.innerHTML;
    const originalText = button.dataset.text;
    const { success } = button.dataset;

    // create a dummy input to copy from
    const dummyInput = document.createElement('textarea');
    const message = this.socialShareTextTarget.value;

    event.preventDefault();

    dummyInput.value = message;
    document.body.appendChild(dummyInput);
    dummyInput.select();
    document.execCommand('copy');
    document.body.removeChild(dummyInput);

    // update the button text for 1,5 seconds
    button.innerHTML = originalCaption.replace(/&amp;/g, '&').replace(originalText, success);
    button.disabled = true;
    setTimeout(() => {
      button.innerHTML = originalCaption;
      button.disabled = false;

      const win = window.open(button.href, '_blank');

      if (!win || win.closed || typeof win.closed === 'undefined') {
        window.location.href = button.href;
      } else {
        win.focus();
      }
    }, 1500);
  }
}
