// query Params are parsed in this way to avoid a large URLSearchParams polyfill
// (since IE doesn't support it) and optimize for performance
const queryParams = () => window.location.href.split('?')[1];
const newQueryParams = (params, couponValue) => {
  const queryParamsArray = [];
  const couponKeyValue = `coupon=${couponValue}`;
  let isCouponParamPresent = false;

  if (params === undefined) {
    return couponKeyValue;
  }
  params.split('&').forEach((keyValue) => {
    const paramKey = keyValue.split('=')[0];
    if (paramKey === 'coupon') {
      isCouponParamPresent = true;
      queryParamsArray.push(couponKeyValue);
    } else if (paramKey !== 'coupon_action') {
      queryParamsArray.push(keyValue);
    }
  });

  if (!isCouponParamPresent) {
    queryParamsArray.push(couponKeyValue);
  }

  return queryParamsArray.join('&');
};

const verifyCouponHandler = (event) => {
  const button = event.target;
  const input = document.querySelector('.js-coupon-code-field');
  const removeInput = document.querySelector('.js-remove-coupon');
  let selectedPaymentMethod = '';
  const paymentMethodValue = document.querySelector("input[type='radio'][name='shop_payment_installment[selected_payment_method]']:checked");

  if (paymentMethodValue) {
    selectedPaymentMethod = paymentMethodValue.value;
  }

  if (removeInput) {
    let newUrl = `${window.location.href.split('?')[0]}?coupon_action=remove`;

    if (selectedPaymentMethod) {
      newUrl += `&selected_payment_method=${selectedPaymentMethod}`;
    }

    window.location.href = newUrl;
  }

  if (input.value && !removeInput) {
    const currentUrlParams = new URLSearchParams(newQueryParams(queryParams(), input.value));
    const urlParamsProcessed = Object.fromEntries(currentUrlParams);

    if ('selected_payment_method' in urlParamsProcessed) delete urlParamsProcessed.selected_payment_method;

    let newUrl = `${button.dataset.baseUrl}?${new URLSearchParams(urlParamsProcessed).toString()}`;

    if (selectedPaymentMethod) {
      newUrl += `&selected_payment_method=${selectedPaymentMethod}`;
    }

    window.location.href = newUrl;
  }
};

const verifyCouponElement = (verifyCouponBtn) => {
  if (verifyCouponBtn) verifyCouponBtn.addEventListener('click', verifyCouponHandler);
};

const verifyCouponDisconnect = (verifyCouponBtn) => {
  if (verifyCouponBtn) verifyCouponBtn.removeEventListener('click', verifyCouponHandler);
};

export default () => {
  const verifyCouponBtn = document.querySelector('.js-verify-coupon');
  verifyCouponElement(verifyCouponBtn);

  return () => {
    verifyCouponDisconnect(verifyCouponBtn);
  };
};
