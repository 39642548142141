// controllers/enrollment_engine/autocharge_preferences_controller.js
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['applyButton', 'autochargeChecked']

  toggleApplyButton () {
    if (this.autochargeCheckedTargets.some(checkBox => checkBox.checked)) {
      this.applyButtonTarget.classList.remove('disabled');
    } else {
      this.applyButtonTarget.classList.add('disabled');
    }
  }
}
