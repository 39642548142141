const sendTrackpointEvent = (event) => {
  if (window.trackPoint === undefined) {
    if (window.trackpointQueue === undefined) {
      window.trackpointQueue = [];
    }
    window.trackpointQueue.push(event);
  } else {
    window.trackPoint.trigger(event);
  }
};

export default sendTrackpointEvent;
