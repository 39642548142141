import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'linkedinTextInput',
    'twitterTextInput',
    'twitterButton',
    'facebookTextInput',
    'facebookButton',
  ]

  copyLinkedinText () {
    this.linkedinTextInputTarget.select();
    document.execCommand('copy');
  }

  updateTwitterUrl () {
    const twitterShareUrl = new URL(this.twitterButtonTarget.getAttribute('href'));

    twitterShareUrl.searchParams.set('text', this.twitterTextInputTarget.value);
    this.twitterButtonTarget.setAttribute('href', twitterShareUrl);
  }

  updateFacebookUrl () {
    const facebookShareUrl = new URL(this.facebookButtonTarget.getAttribute('href'));

    facebookShareUrl.searchParams.set('quote', this.facebookTextInputTarget.value);
    this.facebookButtonTarget.setAttribute('href', facebookShareUrl);
  }
}
