// query Params are parsed in this way to avoid a large URLSearchParams polyfill
// (since IE doesn't support it) and optimize for performance
const submitEmailHandler = (e) => {
  const input = document.querySelector('.js-refer-email-field');
  e.preventDefault();
  if (input.value && !input.classList.contains('form__input--invalid')) {
    const indexOfQuestionMark = window.location.href.indexOf('?');
    const hasQueryParams = () => indexOfQuestionMark !== -1;
    const queryParams = () => window.location.href.slice(indexOfQuestionMark + 1);
    window.location.href = `${
      e.target.dataset.baseUrl
    }${
      hasQueryParams() ? `?${queryParams()}&` : '?'
    }advocate_email=${
      encodeURIComponent(input.value)
    }`;
  }
};

const submitEmailElement = (submitEmailButton) => {
  submitEmailButton.addEventListener('click', submitEmailHandler);
};

const submitEmailDisconnect = (submitEmailButton) => {
  submitEmailButton.removeEventListener('click', submitEmailHandler);
};

export default () => {
  const submitEmailButton = document.querySelector('.js-refer-submit-email');
  if (submitEmailButton) submitEmailElement(submitEmailButton);

  return () => {
    if (submitEmailButton) submitEmailDisconnect(submitEmailButton);
  };
};
