// controllers/enrollment_engine/referral_controller.js
import { Controller } from '@hotwired/stimulus';
import submitEmail from '../../modules/referrals/submitEmail';
import copyText from '../../modules/utils/copyText';

export default class extends Controller {
  static targets = [
    'referralEmails',
    'referralSubmit',
  ]

  connect () {
    this.disconnectListeners = [
      copyText(),
      submitEmail(),
    ];
  }

  disconnect () {
    this.disconnectListeners.forEach(disconnectListener =>
      disconnectListener(),
    );
  }

  updateTrackingData () {
    const newAnalytics = JSON.parse(this.referralSubmitTarget.dataset.analytics)[0];
    const eventProperties = newAnalytics.properties;
    const friendEmails = this.referralEmailsTarget.value.split(',');
    eventProperties.friend_emails = friendEmails.map(email => email.trim());
    newAnalytics.properties = eventProperties;
    this.referralSubmitTarget.dataset.analytics = JSON.stringify([newAnalytics]);
  }
}
