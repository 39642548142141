import bootstrapper from '../bootstrapper';
import {
  displayFlashErrorMessage, removeFlashErrorMessage, disableItems, enableItems,
} from './checkoutHelpers';

class PaymentDescription {
  constructor (numberOfInstallments, paymentMethod, paymentInstallmentId) {
    this.numberOfInstallments = numberOfInstallments || 1;
    this.paymentMethod = paymentMethod;
    this.paymentInstallmentId = paymentInstallmentId;
  }

  requestParams = () => {
    const currentUrlParams = new URLSearchParams(window.location.search);
    const urlParamsProcessed = Object.fromEntries(currentUrlParams);
    if (this.paymentMethod && 'selected_payment_method' in urlParamsProcessed) delete urlParamsProcessed.selected_payment_method;
    const params = {
      'shop_payment_installment[number_of_installments]': this.numberOfInstallments,
      'shop_payment_installment[selected_payment_method]': this.paymentMethod,
      ...urlParamsProcessed,
    };
    if (this.paymentInstallmentId) {
      params.id = this.paymentInstallmentId;
    }
    if (!this.paymentMethod) delete params['shop_payment_installment[selected_payment_method]'];

    return params;
  };

  requestUrl = () => {
    const enrollmentID = document.querySelector('form[data-enrollment-id]').dataset.enrollmentId;
    const serverUrl = `${window.location.origin}/${bootstrapper.locale}/enrollments/${enrollmentID}/payment_installments/payment_description`;
    const url = `${serverUrl}?${new URLSearchParams(this.requestParams()).toString()}`;
    const cleanUrl = window.location.href.split('?')[0];
    window.history.replaceState(null, null, cleanUrl);

    return url;
  };

  paymentDescriptionRequest = async () => {
    const CSRFToken = document.querySelector('input[name="authenticity_token"]')?.value;
    const options = {
      method: 'GET',
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': CSRFToken,
      },
    };

    return fetch(this.requestUrl(), options);
  };

  processedResponse = async () => {
    const response = await this.paymentDescriptionRequest();
    const responseData = await response.text();

    if (response.ok) return responseData;

    throw new Error(JSON.parse(responseData).error);
  };

  reload = () => {
    disableItems();
    removeFlashErrorMessage();

    this.processedResponse()
      .then((response) => {
        // eslint-disable-next-line no-undef
        Turbo.renderStreamMessage(response);
        enableItems();
      })
      .catch((error) => {
        displayFlashErrorMessage(error.message);
      });
  };
}


export default PaymentDescription;
